import * as Sentry from '@sentry/nextjs'
import { setClarity } from '@patrianna-payments/analytics/clarity/events'

import type { OfferPurchaseType } from '@patrianna/shared-patrianna-types/store/ShopModule'
import type { OfferDeclineNotification } from '@patrianna/shared-patrianna-types/websocket/notifications'
import type { GetPaymentOrderRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetPaymentOrderResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import type { WSResponse } from '@patrianna/shared-patrianna-types/websocket/types'
import {
  fetchSavedCards,
  pwmbOpenIframe,
  repeatPaymentAfterErrorByProvider,
  setIsPaymentFlowInProcess,
  setQuickPurchaseStage,
} from '@patrianna-payments/shared-store/payments/store/actions'
import {
  offerDeclinePWMBHandler,
  paymentErrorsHandler,
  quickPurchasePaymentErrorsHandler,
} from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import { getPaymentMethodsSelector } from '@patrianna-payments/shared-store/payments/store/selectors'
import type {
  BuyShopOfferProps,
  CreateOrderResponseCardPaymentErrorProps,
  OfferDeclinePWMBProps,
} from '@patrianna-payments/shared-store/payments/store/types'
import { getOfferDeclinePWMBPayload } from '@patrianna-payments/shared-store/payments/store/utils'
import { skipOtpSelector } from '@patrianna-payments/shared-store/skip-otp'
import type { CreateOrderResponse, ConfirmOrderResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { loadScript } from '@patrianna/shared-utils'
import type { TypedThunk } from 'src/store'
import { setShowDailyBonusCaptcha } from 'store/modules/bonusesFlow/slice'
import { getAvailablePaymentProvidersRequest } from 'store/modules/appConfig/actions'
import { closeAllDialogs, openDialog, removeDialogByName, replaceDialog } from 'store/modules/dialog/actions'
import { clearSlotGameFlow } from 'store/modules/slotGameFlow/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { getAccountInfo, getLiveChatSettingsRequest, setFirstDepositDate } from 'store/modules/user/actions'
import {
  getLocationDataSelector,
  sweepstakeEnabledSelector,
  getPayWithMyBankConfigSelector,
  isGetOrUpdatePaymentProvidersSelector,
} from 'src/store/modules/appConfig/selectors'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'
import { purchaseLimitsSelector } from 'store/modules/shop/selectors'
import {
  getLiveChatSettingsSelector,
  getUserAccountIdSelector,
  getUserHashSelector,
  getUserPaymentMetaDataInfo,
  getUserRestrictionsSelector,
  getUserSelector,
  isLoggedInSelector,
} from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
import { trackClickOnOffer, trackSuccessPayment } from 'analyticActions/common'
import { trackFirstPurchase } from 'analyticActions/common/trackFirstPurchase'
import { getSeonSessionId } from 'services/seon'
import { getOffers } from 'store/modules/shop/actions'
import { trackEvent } from 'config/analytic'
import type { PaymentWithPWMBOpenIframeProps } from '@patrianna-payments/shared-store/payments/store/types'

const clearPaymentState = (): TypedThunk => (dispatch) => {
  dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
  dispatch(removeDialogByName({ modalName: 'PAYMENT_DIALOG' }))
  dispatch(removeDialogByName({ modalName: 'PAYMENT_CVV_DIALOG' }))
  window.history.replaceState({}, document.title, window.location.href.replace(window.location.search, ''))
}

export const handleFinishedPaymentFlowAction =
  ({ transactionId }: { transactionId: string }): TypedThunk =>
  (dispatch, getState) => {
    const dialogs = getDialogStackSelector(getState())
    const confirmationSuccessMessage = dialogs?.find?.(
      // @ts-ignore
      (el) => el.modalName === 'ORDER_CONFIRMATION_MESSAGE' && el.dialogProps?.status === 'success'
    )

    dispatch(clearPaymentState())

    if (!confirmationSuccessMessage) {
      dispatch(
        replaceDialog({
          modalName: 'ORDER_CONFIRMATION_MESSAGE',
          dialogProps: { status: 'waiting', transactionId },
        })
      )
    }
  }

export const handleFinishedQuickPurchasePaymentFlowAction =
  (res: CreateOrderResponse | ConfirmOrderResponse): TypedThunk =>
  (dispatch) => {
    dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
    dispatch(
      setQuickPurchaseStage({
        stage: 'success_purchase',
        params: { billingDescriptor: res.billingDescriptor, offer: res.offer },
      })
    )
    dispatch(handleOfferQuickPurchaseSuccessAction(res as CreateOrderResponse))
  }

export const handleOfferPurchaseSuccessAction =
  (response: OfferPurchaseType | CreateOrderResponse, isQuickPurchase?: boolean): TypedThunk =>
  async (dispatch, getState, extraArg) => {
    const user = getUserSelector(getState())
    const userHash = getUserHashSelector(getState())
    const liveChatSettings = getLiveChatSettingsSelector(getState())

    await getOffers(true)(dispatch, getState, extraArg)

    if (!isQuickPurchase) {
      dispatch(removeDialogByName({ modalName: 'SHOP_DIALOG' }))
      dispatch(clearPaymentState())
      dispatch(
        replaceDialog({
          modalName: 'ORDER_CONFIRMATION_MESSAGE',
          dialogProps: {
            status: 'success',
            provider: response.provider,
            billingDescriptor: response.billingDescriptor,
          },
        })
      )
    }

    dispatch(fetchSavedCards())
    dispatch(getAccountInfo())
    dispatch(setShowDailyBonusCaptcha({ shouldShow: false }))

    trackSuccessPayment(response, user, userHash)

    if (!liveChatSettings.showLiveChat) {
      dispatch(getLiveChatSettingsRequest())
    }

    if (response.isFirstDeposit) {
      if ('firstDeposit' in response) {
        dispatch(setFirstDepositDate({ firstDepositDate: response.firstDeposit }))
      }

      if ('firstDepositDate' in response) {
        dispatch(setFirstDepositDate({ firstDepositDate: response.firstDepositDate as string }))
      }

      dispatch(getAvailablePaymentProvidersRequest())

      trackFirstPurchase({
        amount: response.amount,
        currency: response.currency,
        offerCode: response.offer.code,
        city: response?.city || user?.kycInfo?.city || user?.softKycInfo?.city,
        zip: response?.zip || user?.kycInfo?.zip || user?.softKycInfo?.zip,
        external_id: userHash,
        transactionId: response.transactionId,
        value: response.amount,
        transactionTotal: response.offer.price,
        userId: user.id,
        provider: response.provider,
      })
    }

    if (window.location.href.includes('/play')) {
      dispatch(clearSlotGameFlow())
    }
  }

export const handleOfferQuickPurchaseSuccessAction =
  (response: CreateOrderResponse): TypedThunk =>
  (dispatch, getState) => {
    const accountId = getUserAccountIdSelector(getState())

    void trackEvent('QuickPurchaseSuccessful', {
      category: response.offer.code,
      label: `Provider - ${response.provider}`,
      user_id: accountId,
      transactionTotal: response.offer.price,
      transactionId: response.transactionId,
    })

    dispatch(handleOfferPurchaseSuccessAction(response, true))
  }

export const handlePaymentErrorsHandlerAction =
  (error: WSResponse, isQuickPurchase?: boolean): TypedThunk =>
  (dispatch, getState) => {
    if (!error?.status) {
      if (process.env.ENABLE_LOG_MESSAGES) {
        Sentry.captureMessage(`non gateway error ${JSON.stringify(error)}`)
      }
    } else {
      const { status, body } = error

      // close payment dialogs
      dispatch(clearPaymentState())

      if ('isFirstDeposit' in body && body.isFirstDeposit) {
        dispatch(getAvailablePaymentProvidersRequest())
      }

      switch (body?.type) {
        case 'CreateOrderResponse': {
          trackEvent('submitted_payment_error', {
            category: 'CreateOrderRequest',
            label: status?.errorCode || status?.errorText,
          })

          if (body.limitAmount) {
            dispatch(
              openDialog({
                modalName: 'PURCHASE_LIMIT_DIALOG',
                dialogProps: {
                  limitAmount: body.limitAmount,
                  limitAvailable: body.limitAvailable,
                  limitPeriod: body.limitPeriod,
                  limitEnd: body.limitEnd,
                },
              })
            )
          }

          if (body.requestKyc) {
            dispatch(openDialog({ modalName: 'GET_VERIFIED_DIALOG' }))
          }

          break
        }
        case 'RepeatPaymentOrderResponse': {
          trackEvent('submitted_payment_error', {
            category: 'RepeatPaymentOrderResponse',
            label: 'RepeatPaymentOrderResponse',
          })

          break
        }
        default: {
          // eslint-disable-next-line
          console.log('payment error', error)
        }
      }

      if (status?.errorText) {
        if (status?.errorText?.includes?.('Transaction already processed')) {
          const dialogs = getDialogStackSelector(getState()).map((el) => el.modalName)

          Sentry.captureMessage(`Transaction already processed, dialog: ${JSON.stringify(dialogs)}`)
          setClarity('duplicate_transaction', `Transaction already processed req: ${body.type}`)
        }

        if (isQuickPurchase) {
          if (body?.type === 'CreateOrderResponse' && (body.limitAmount || body.requestKyc)) {
            dispatch(setQuickPurchaseStage({ stage: 'quick_purchase', params: null })) // [FYI]: reset quick purchase flow
            dispatch(openSnackbar({ message: status.errorText }))
          } else {
            dispatch(setQuickPurchaseStage({ stage: 'failed_purchase', params: { error: status.errorText } }))
          }
        } else {
          if (
            (status?.errorCode as string) !== 'err_kyc_required' &&
            (status?.errorCode as string) !== 'err_kyc_required_high_risk'
          ) {
            dispatch(openSnackbar({ message: status.errorText }))
          }
        }
      }
    }
  }

export const handleQuickPurchasePaymentErrorsHandlerAction =
  (error: WSResponse): TypedThunk =>
  (dispatch, getState) => {
    const { body = {}, status } = error
    const accountId = getUserAccountIdSelector(getState())

    void trackEvent('QuickPurchaseFailed', {
      category: 'offerCode' in body ? body?.offerCode : (body as CreateOrderResponse)?.offer?.code || 'Empty offerCode',
      label: `Provider - ${(body as CreateOrderResponse)?.provider || 'empty'}`,
      user_id: accountId,
      transactionTotal: 'price' in body ? body?.price : (body as CreateOrderResponse)?.offer?.price || 'empty',
      error: status?.errorCode || status?.errorText,
    })

    dispatch(handlePaymentErrorsHandlerAction(error, true))
  }

export const handlePurchaseResponseListenerByRouterAction =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const query = new URLSearchParams(window.location.search)
    const user = getUserSelector(getState())
    const userHash = getUserHashSelector(getState())
    const liveChatSettings = getLiveChatSettingsSelector(getState())

    if (query.get('payment_status')) {
      if (query.get('payment_status') === 'success') {
        if (
          query.get('transaction_id') &&
          query.get('amount') &&
          query.get('description') &&
          query.get('offer') &&
          query.get('currency')
        ) {
          const response = {
            provider: query.get('originalProvider'),
            transactionId: query.get('transaction_id'),
            currency: query.get('currency'),
            offer: {
              price: Number(query.get('amount')),
              title: query.get('description'),
              code: query.get('offer'),
            },
            city: user?.kycInfo?.city || user?.softKycInfo?.city,
            zip: user?.kycInfo?.zip || user?.softKycInfo?.zip,
          }

          trackSuccessPayment(response, user, userHash)

          const data: GetPaymentOrderRequest = {
            type: 'GetPaymentOrderRequest',
            transactionId: response.transactionId,
          }

          gateway
            .emit<GetPaymentOrderResponse>(data)
            .then((body) => {
              if (body.success) {
                dispatch(
                  replaceDialog({
                    modalName: 'ORDER_CONFIRMATION_MESSAGE',
                    dialogProps: { status: 'success', provider: body.provider },
                  })
                )

                if (!liveChatSettings.showLiveChat) {
                  dispatch(getLiveChatSettingsRequest())
                }

                if (query.get('isFirstDeposit')) {
                  dispatch(getAvailablePaymentProvidersRequest())

                  trackFirstPurchase({
                    amount: response.offer.price,
                    currency: response.currency,
                    offerCode: response.offer.code,
                    city: response.city,
                    zip: response.zip,
                    external_id: userHash,
                    transactionId: response.transactionId,
                    value: response.offer.price,
                    transactionTotal: response.offer.price,
                    userId: user.id,
                    provider: response.provider,
                  })
                }
              }
            })
            .catch((err) => {
              dispatch(errorHandler(err, data))
            })
        }

        dispatch(replaceDialog({ modalName: 'ORDER_CONFIRMATION_MESSAGE', dialogProps: { status: 'waiting' } }))
      } else if (query.get('payment_status') === 'cancelled') {
        dispatch(
          replaceDialog({
            modalName: 'ORDER_CONFIRMATION_MESSAGE',
            dialogProps: { status: 'cancelled', provider: query.get('originalProvider') || null },
          })
        )
      }

      window.history.replaceState({}, document.title, window.location.href.replace(window.location.search, ''))
    }
  }

export const handleOfferDeclinePWMBHandlerAction =
  ({ tempToken, reason, offer, transactionId, code, errCode }: OfferDeclinePWMBProps): TypedThunk =>
  (dispatch, getState) => {
    const payWithMyBankConfig = getPayWithMyBankConfigSelector(getState())
    const user = getUserSelector(getState())
    const location = getLocationDataSelector(getState())
    const userPaymentMetaData = getUserPaymentMetaDataInfo(getState())

    const data = getOfferDeclinePWMBPayload({
      payWithMyBankConfig,
      tempToken,
      transactionId,
      code,
      user,
      location,
      firstDeposit: userPaymentMetaData.firstDeposit,
      description: offer.title,
    })

    if (errCode === 'expired') {
      if (!window?.PayWithMyBank) {
        loadScript(`${process.env.PAY_WITH_MY_BANK}${payWithMyBankConfig.id}`).then(() => {
          window.PayWithMyBank.establish(data)
        })
      } else {
        window.PayWithMyBank.establish(data)
      }
    } else {
      dispatch(removeDialogByName({ modalName: 'PAYMENT_DIALOG' }))
      dispatch(removeDialogByName({ modalName: 'PAYMENT_CVV_DIALOG' }))
      dispatch(replaceDialog({ modalName: 'PAYMENT_ERROR_DIALOG', dialogProps: { offer, reason } }))
    }
  }

export const handleOfferDeclineNotificationHandlerAction =
  ({ tempToken, reason, provider, offer, transactionId, code, errCode }: OfferDeclineNotification): TypedThunk =>
  (dispatch) => {
    dispatch(fetchSavedCards())
    dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))

    trackEvent('submitted_payment_error', { category: 'CreateOrderRequest', label: reason })

    if (provider === 'pay_with_my_bank') {
      dispatch(
        offerDeclinePWMBHandler({
          tempToken,
          reason,
          offer,
          transactionId,
          code,
          errCode,
        })
      )

      return null
    }

    dispatch(removeDialogByName({ modalName: 'PAYMENT_DIALOG' }))
    dispatch(removeDialogByName({ modalName: 'PAYMENT_CVV_DIALOG' }))
    dispatch(openSnackbar({ message: reason || 'Payment has failed' }))

    return null
  }

export const fetchPaymentData = (): TypedThunk => (dispatch, getState) => {
  const isProvidersShouldUpdate = isGetOrUpdatePaymentProvidersSelector(getState())
  const paymentMethods = getPaymentMethodsSelector(getState())

  if (!paymentMethods) {
    dispatch(fetchSavedCards())
  }

  if (isProvidersShouldUpdate) {
    dispatch(getAvailablePaymentProvidersRequest())
  }
}

export const handleOpenPaymentProvidersDialogAction =
  (offerCode: string): TypedThunk =>
  (dispatch) => {
    dispatch(fetchPaymentData())
    // pay with card
    // skrill pwmb
    dispatch(openDialog({ modalName: 'PAYMENT_DIALOG', dialogProps: { code: offerCode } }))
  }

export const handleBuyShopOfferAction =
  ({ offer, feature = 'pop_up', routerPush }: BuyShopOfferProps): TypedThunk =>
  (dispatch, getState) => {
    const purchaseLimits = purchaseLimitsSelector(getState())
    const isSkipOtp = skipOtpSelector(getState())
    const isLoggedIn = isLoggedInSelector(getState())
    const userRestrictions = getUserRestrictionsSelector(getState())
    const sweepstakeEnabled = sweepstakeEnabledSelector(getState())

    trackClickOnOffer({ offer, mode: sweepstakeEnabled ? 'SC' : 'GC', feature, page_path: window.location.pathname })

    if (!isLoggedIn) {
      dispatch(closeAllDialogs())
      routerPush(ROUTES.REGISTER)

      return null
    }

    if (!isSkipOtp) {
      dispatch(openDialog({ modalName: 'PHONE_VERIFICATION_DIALOG' }))

      return null
    }

    if (userRestrictions.includes('no_purchase')) {
      dispatch(openDialog({ modalName: 'GET_VERIFIED_DIALOG' }))

      return null
    }

    // check for purchase limits
    if (purchaseLimits && Number(purchaseLimits.limitAvailable) < offer.price) {
      dispatch(openDialog({ modalName: 'PURCHASE_LIMIT_DIALOG', dialogProps: { ...purchaseLimits } }))

      return null
    }

    // routerPush(
    //   `${window.location.pathname}?dialogName=PAYMENT_DIALOG&dialogProps.code=${offer.code}&isReplaceDisable=true`
    // )

    dispatch(handleOpenPaymentProvidersDialogAction(offer.code))

    return null
  }

export const handleCreateOrderResponseCardPaymentErrorHandlerAction =
  (props: CreateOrderResponseCardPaymentErrorProps): TypedThunk =>
  async (dispatch) => {
    const { error, options } = props

    if (error?.status?.errorCode === 'err_payment_input_cvv') {
      dispatch(removeDialogByName({ modalName: 'SHOP_DIALOG' }))
      dispatch(removeDialogByName({ modalName: 'PAYMENT_DIALOG' }))
      dispatch(
        openDialog({
          modalName: 'PAYMENT_CVV_DIALOG',
          dialogProps: {
            ...(options.isCustomProvider ? { provider: options.provider } : {}),
            cardToken: options.paymentMethodId,
            offerCode: options.offerCode,
            sourceId: error.body.sourceId,
          },
        })
      )
    } else if (error?.status?.errorCode === 'err_payment_routing') {
      const seonSessionId = await getSeonSessionId()

      dispatch(repeatPaymentAfterErrorByProvider({ ...props, seonSessionId }))
    } else {
      dispatch(paymentErrorsHandler(error))
      dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
    }
  }

export const handleCreateOrderResponseCardQuickPurchasePaymentErrorHandlerAction =
  (props: CreateOrderResponseCardPaymentErrorProps): TypedThunk =>
  async (dispatch, getState) => {
    const { error, options } = props
    const accountId = getUserAccountIdSelector(getState())
    const errorCode = error?.status?.errorCode

    const trackErrorEvent = () => {
      void trackEvent('QuickPurchaseFailed', {
        category: options.offerCode,
        label: `Provider - ${error.body.provider || 'empty'}`, // [FYI]: missing - get from BE as ""
        user_id: accountId,
        transactionTotal: error.body.price,
        error: errorCode || error?.status?.errorText,
      })
    }

    if (errorCode === 'err_payment_input_cvv') {
      trackErrorEvent()
      dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
      dispatch(setQuickPurchaseStage({ stage: 'purchase_in_progress', params: { sourceId: error.body.sourceId } }))
    } else if (errorCode === 'err_payment_routing') {
      trackErrorEvent()

      const seonSessionId = await getSeonSessionId()

      dispatch(repeatPaymentAfterErrorByProvider({ ...props, seonSessionId, isQuickPurchase: true }))
    } else {
      dispatch(quickPurchasePaymentErrorsHandler(error))
      dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
    }
  }

export const handlePaymentWithPWMBOpenIframeAction =
  ({ offer, seonSessionId }: PaymentWithPWMBOpenIframeProps): TypedThunk =>
  (dispatch, getState) => {
    const user = getUserSelector(getState())
    const location = getLocationDataSelector(getState())
    const payWithMyBankConfig = getPayWithMyBankConfigSelector(getState())
    const userPaymentMetaData = getUserPaymentMetaDataInfo(getState())

    dispatch(
      pwmbOpenIframe({
        seonSessionId,
        user,
        location,
        userPaymentMetaData,
        payWithMyBankConfig,
        offer,
      })
    )
  }
