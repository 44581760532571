import type { RedeemPolicyField, RedeemTabStatus } from '@patrianna-payments/shared-store/redeem/types'
import { RedeemUserStatus } from '@patrianna/gateway'

export const validateRedeemAmount = ({
  generalRedeemPolicy,
  balance,
  amount,
  providerRedeemPolicy,
  t,
}: {
  generalRedeemPolicy: {
    minAmount: number
    maxAmount: number
  }
  providerRedeemPolicy: RedeemPolicyField | null
  balance: number
  amount: number
  t: (key: string) => string
}) => {
  if (!amount) {
    return t('errors.please_input_redeemable_amount')
  }

  if (
    providerRedeemPolicy &&
    'availableToRedeem' in providerRedeemPolicy &&
    amount > providerRedeemPolicy.availableToRedeem
  ) {
    return `${t('errors.available_to_redeem_part1')}${providerRedeemPolicy.availableToRedeem.toFixed(2)} ${t(
      'available_to_redeem_part2'
    )}`
  }

  if (amount < generalRedeemPolicy.minAmount || (providerRedeemPolicy && amount < providerRedeemPolicy.minAmount)) {
    return t('errors.low_redeemable_sweepstakes_coins')
  }

  if (amount > balance) {
    return t('errors.do_not_have_enough_redeemable_sc')
  }

  if (amount > generalRedeemPolicy.maxAmount || (providerRedeemPolicy && amount > providerRedeemPolicy.maxAmount)) {
    return `${t('errors.amount_to_redeem_is_more_than_max')} ${generalRedeemPolicy.maxAmount.toFixed(2)}`
  }

  return null
}

const ALL_STATUS = 'all'
export const getStatuses = (status: RedeemTabStatus = ALL_STATUS) => {
  return status === ALL_STATUS
    ? [RedeemUserStatus.PENDING, RedeemUserStatus.PROCESSED, RedeemUserStatus.CANCELED]
    : [status]
}
