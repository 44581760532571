import { Currency } from '@patrianna/gateway'
import type { RedeemMoneyArgs } from '@patrianna-payments/shared-store/redeem/store/actions'
import {
  confirmRedeemHandler as confirmRedeemHandlerBase,
  redeemFlowStart as redeemFlowStartBase,
  redeemMoney as redeemMoneyBase,
  resolveRightRedeemWay as resolveRightRedeemWayBase,
} from '@patrianna-payments/shared-store/redeem/store/actions'
import {
  getRedeemHistoryCurrentPageSelector,
  getRedeemPolicyByCurrencySelector,
  getSCProvidersRedeemPoliciesSelector,
  getRedeemsTotalCountSelector,
} from '@patrianna-payments/shared-store/redeem/store/selectors'
import { setCurrentPageRedeemHistory } from '@patrianna-payments/shared-store/redeem/store/actions'
import type { Providers, ProvidersData, RedeemTabStatus } from '@patrianna-payments/shared-store/redeem/types'
import type { RealCurrency } from '@patrianna-payments/shared-store/redeem/types/realCurrency'
import type { WithdrawPaymentProviders } from '@patrianna-payments/shared-store/redeem/types/withdrawPaymentProviders'
import type { TypedDispatch, TypedThunk } from 'src/store'
import { validateRedeemAmount } from 'src/store/modules/redeem/utils'
import { openDialog } from 'store/modules/dialog/actions'
import {
  getIsSoftKycRequiredEnabledSelector,
  sweepstakeEnabledSelector,
  getWithdrawProvidersSelector,
  isGetOrUpdatePaymentProvidersSelector,
} from 'store/modules/appConfig/selectors'
import { cancelRedeemRequest, getRedeemHistoryRequest } from '@patrianna-payments/shared-store/redeem/store/actions'
import { getBalanceByIDSelector } from 'store/modules/currencies/selectors'
import {
  KYCinReviewSelector,
  emailHiddenSelector,
  getIsSoftKYCAvailableSelector,
  getUserRealEmailSelector,
  getUserRestrictionsSelector,
  isGuestModeSelector,
  isKYCRequiredSelector,
  getUserAccountIdSelector,
} from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
import { getStatuses } from './utils'
import { trackEvent } from 'config/analytic'

export const redeemFlowStart =
  (pushRouter: (route: string) => void): TypedThunk =>
  (dispatch: TypedDispatch, getState) => {
    const isGuestMode = isGuestModeSelector(getState())
    const sweepstakeEnabled = sweepstakeEnabledSelector(getState())
    const isEmailHidden = emailHiddenSelector(getState())
    const userRestrictions = getUserRestrictionsSelector(getState())
    const isShouldProvidersUpdate = isGetOrUpdatePaymentProvidersSelector(getState())
    const redeemProviders = getWithdrawProvidersSelector(getState())
    const isSoftKYCEntered = getIsSoftKYCAvailableSelector(getState())
    const isSoftKYCRequired = getIsSoftKycRequiredEnabledSelector(getState())

    dispatch(
      redeemFlowStartBase({
        isGuestMode,
        sweepstakeEnabled,
        isEmailHidden,
        userRestrictions,
        // hack, will fix it in pkg
        redeemProviders: isShouldProvidersUpdate ? null : (redeemProviders as unknown as WithdrawPaymentProviders),
        registerRoute: ROUTES.REGISTER,
        // a/b testing
        isSoftKYCAvailable: isSoftKYCRequired === null ? undefined : isSoftKYCEntered,
        pushRouter,
      })
    )
  }

export const redeemMoney =
  (redeemFields: RedeemMoneyArgs['redeemFields'], seonSessionId: string): TypedThunk =>
  (dispatch: TypedDispatch) => {
    const notifyError = (message: string) => {
      dispatch(openDialog({ modalName: 'REDEEM_INFO_BALANCE_DIALOG', dialogProps: { message } }))
    }

    dispatch(redeemMoneyBase({ notifyError, redeemFields, seonSessionId }))
  }

export const resolveRightRedeemWay =
  (currency: RealCurrency): TypedThunk =>
  (dispatch: TypedDispatch, getState) => {
    const balance = getBalanceByIDSelector(getState(), currency)
    const kycRequired = isKYCRequiredSelector(getState())
    const KYCinReview = KYCinReviewSelector(getState())

    const notifyError = () => dispatch(openDialog({ modalName: 'REDEEM_INFO_BALANCE_DIALOG' }))

    dispatch(
      resolveRightRedeemWayBase({ redeemable: balance.redeemable, KYCinReview, kycRequired, currency, notifyError })
    )
  }

const allowedProviders: Providers[] = ['ACH', 'Skrill', 'MasspayACH']
export const redeemMoneyRequest =
  ({
    amounts,
    t,
    seonSessionId,
  }: {
    amounts: ProvidersData
    t: (key: string) => string
    seonSessionId: string
  }): TypedThunk =>
  (dispatch: TypedDispatch, getState) => {
    const email = getUserRealEmailSelector(getState())
    const balance = getBalanceByIDSelector(getState(), Currency.SC)
    const providersRedeemPolicy = getSCProvidersRedeemPoliciesSelector(getState(), allowedProviders)
    const redeemPolicy = getRedeemPolicyByCurrencySelector(getState(), Currency.SC)

    dispatch(
      confirmRedeemHandlerBase({
        amounts,
        currency: balance.currency as RealCurrency,
        seonSessionId,
        email,
        notify: (amount: number, providerType: string) => {
          dispatch(
            openDialog({
              modalName: 'WITHDRAW_SWEEPSTSKE_DIALOG',
              dialogProps: {
                amount,
                withdrawMethod: {
                  withdrawMethod: { type: providerType },
                },
              },
            })
          )
        },
        notifyError: (error: string) => {
          dispatch(openDialog({ modalName: 'REDEEM_INFO_BALANCE_DIALOG', dialogProps: { message: error } }))
        },
        validateRedeemAmount: (amount: number, provider: Providers) => {
          return validateRedeemAmount({
            generalRedeemPolicy: redeemPolicy,
            providerRedeemPolicy: providersRedeemPolicy[provider] || null,
            balance: balance.redeemable,
            amount,
            t,
          })
        },
      })
    )
  }

export const redeemTrackGAEvent =
  ({ event, options }: { event: string; options: { [key: string]: unknown } }): TypedThunk =>
  (_, getState) => {
    const userAccountId = getUserAccountIdSelector(getState())

    trackEvent(event, { event, category: userAccountId, ...options })
  }

export const cancelRedeemAndUpdateHistory =
  (id: number, tabName: RedeemTabStatus): TypedThunk =>
  async (dispatch, getState) => {
    const statuses = getStatuses(tabName)
    await dispatch(cancelRedeemRequest(id))
    const totalCount = getRedeemsTotalCountSelector(getState())
    let currentPage = getRedeemHistoryCurrentPageSelector(getState())
    const ITEMS_PER_PAGE = 6

    if (totalCount - 1 <= (currentPage - 1) * ITEMS_PER_PAGE && currentPage > 1) {
      currentPage -= 1
    }

    dispatch(setCurrentPageRedeemHistory({ page: currentPage }))

    await dispatch(getRedeemHistoryRequest(statuses, currentPage))
  }

export const getRedeemHistoryPerTab =
  (tabName: RedeemTabStatus, page: number): TypedThunk =>
  (dispatch) => {
    const statuses = getStatuses(tabName)

    dispatch(setCurrentPageRedeemHistory({ page }))

    dispatch(getRedeemHistoryRequest(statuses, page))
  }
